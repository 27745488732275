// src/components/navigation/NavigationItems.js
import { Home, Book, Mail } from 'lucide-react';

const navigationItems = [
  { path: '/', label: 'Home', icon: <Home className="w-5 h-5" /> },
  { path: '/courses', label: 'Courses', icon: <Book className="w-5 h-5" /> },
  { 
    path: 'mailto:team@newsflashh.com?subject=Feedback',
    label: 'Feedback',
    icon: <Mail className="w-5 h-5" />,
    isExternal: true 
  },
];

export default navigationItems;
