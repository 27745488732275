import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, Filter, GraduationCap, School, X, Loader2, Menu } from 'lucide-react';
import { fetchSearchResults } from '../../utils/api';

const CourseListingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const buildSearchUrl = (searchTerm, selectedCategories) => {
    const baseUrl = '/api/search';
    const queryParams = new URLSearchParams({
      data_type: 'descriptionV2',
      page: 1,
      page_size: 100,
      rephrased_title: searchTerm,
      category: selectedCategories.join(','),
    });

    return `${baseUrl}?${queryParams.toString()}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryFilter = params.get('categories')?.split(',') || [];
    const searchFilter = params.get('search') || '';

    setSelectedCategories(categoryFilter);
    setSearchTerm(searchFilter);
  }, [location.search]);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const data = await fetchSearchResults(buildSearchUrl(searchTerm, selectedCategories));
        const transformedCourses = data.items.map(course => ({
          id: course.id,
          title: course.title,
          description: course.rephrased_description || course.subcategory,
          university: course.university || 'Unknown University',
          category: course.category,
          subcategory: course.subcategory,
          img: course.img,
          professor: course.professor,
          what_You_Learn: course.what_You_Learn,
          prerequisites_for_course: course.prerequisites_for_course,
          lectures: course.lectures,
        }));
        setCourses(transformedCourses);
        setCategories([
          'Computer Science', 'Engineering', 'Physics', 'Mathematics', 'Science',
          'Business & Management', 'Biology', 'Education', 'Chemistry', 'Arts & Humanities',
          'Social Sciences', 'Economics', 'Media & Communication', 'Neuroscience', 'Music',
          'Language', 'Materials Science', 'Artificial Intelligence', 'Languages',
          'Environmental Science', 'Film Studies', 'Data Science', 'Architecture',
          'Medicine & Healthcare', 'Environmental Studies', 'History', 'Cognitive Science',
        ]);
      } catch (err) {
        console.error('Error fetching courses:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [searchTerm, selectedCategories]);

  const toggleCategory = (category) => {
    const updatedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter(item => item !== category)
      : [...selectedCategories, category];

    setSelectedCategories(updatedCategories);
    updateURLParams(searchTerm, updatedCategories);
  };

  const updateURLParams = (search, categories) => {
    const params = new URLSearchParams();
    if (search) params.set('search', search);
    if (categories.length) params.set('categories', categories.join(','));
    navigate({ search: params.toString() });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    updateURLParams(value, selectedCategories);
  };

  const handleCourseClick = (courseId, courseImg) => {
    navigate(`/course-detail/${courseId}`, { state: { courseImg } });
  };

  const filteredCourses = useMemo(() => {
    return courses.filter(course => {
      const matchesSearch = course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            course.university?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategories.length === 0 || selectedCategories.includes(course.category);
      return matchesSearch && matchesCategory;
    });
  }, [courses, searchTerm, selectedCategories]);

  const LoadingSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <div key={item} className="bg-white rounded-lg shadow-lg overflow-hidden animate-pulse">
          <div className="h-48 bg-gray-200" />
          <div className="p-6">
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-4" />
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-4" />
            <div className="h-8 bg-gray-200 rounded w-full" />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Search */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white py-8 sm:py-16">
        <div id="courses-title" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6 sm:mb-8">
            Explore Our Course Catalog
          </h1>
          <div className="max-w-xl mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Search courses..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-full px-4 py-3 pl-12 rounded-lg text-gray-900 text-base sm:text-lg text-center"

                style={{ textAlign: 'center' }} // Center the placeholder text

                />
              <Search className="absolute right-4 top-5 h-5 w-5 text-gray-400" />
              {searchTerm && (
                <button
                  onClick={() => {
                    setSearchTerm('');
                    updateURLParams('', selectedCategories);
                  }}
                  className="absolute right-4 top-3.5 text-gray-400 hover:text-gray-600"
                >
                  <X size={18} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12">
        <div className="lg:hidden mb-4">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="flex items-center space-x-2 bg-white px-4 py-2 rounded-lg shadow-sm"
          >
            <Menu size={20} />
            <span>Filter Categories</span>
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Categories Sidebar */}
          <aside className={`${isSidebarOpen ? 'block' : 'hidden'} lg:block lg:w-64 fixed lg:relative inset-0 z-50 lg:z-0 bg-gray-50/95 lg:bg-transparent`}>
            <div className="bg-white p-6 rounded-lg shadow-lg h-screen lg:h-auto overflow-y-auto">
              <div className="flex justify-between items-center mb-4 lg:hidden">
                <h2 className="font-semibold text-gray-900">Categories</h2>
                <button onClick={() => setIsSidebarOpen(false)}>
                  <X size={20} />
                </button>
              </div>
              <div className="space-y-2">
                {categories.map((category) => (
                  <label key={category} className="flex items-center space-x-2 py-1">
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => toggleCategory(category)}
                      className="form-checkbox text-blue-600 rounded"
                    />
                    <span className="text-gray-600 text-sm sm:text-base">{category}</span>
                  </label>
                ))}
              </div>
            </div>
          </aside>

          {/* Course Grid */}
          <div className="flex-1">
            {loading ? (
              <LoadingSkeleton />
            ) : filteredCourses.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
                {filteredCourses.map((course) => (
                  <div
                    key={course.id}
                    className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col cursor-pointer"
                    onClick={() => handleCourseClick(course.id, course.img)}
                  >
                    <img
                      src={course.img}
                      alt={course.title}
                      className="w-full h-40 sm:h-48 object-cover"
                    />
                    <div className="p-4 sm:p-6 flex flex-col flex-grow">
                      <div className="flex items-center mb-2">
                        <School className="h-4 w-4 text-indigo-600 mr-2" />
                        <span className="text-xs sm:text-sm text-indigo-600 font-semibold">
                          {course.university}
                        </span>
                      </div>
                      <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-2">
                        {course.title}
                      </h3>
                      <div className="flex flex-wrap gap-2 mt-2">
                        <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs sm:text-sm rounded-full">
                          {course.category}
                        </span>
                        <span className="px-2 py-1 bg-gray-100 text-gray-800 text-xs sm:text-sm rounded-full">
                          {course.subcategory}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-500 text-lg">No courses found. Try adjusting your search.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseListingPage;
