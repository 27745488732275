
import React from 'react';
import { Container, Typography, Box, Divider, Paper } from '@mui/material';

const TermCondition = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Terms and Conditions
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <Typography variant="body1" paragraph>
            Welcome to Open Course! These Terms and Conditions outline the rules and regulations for using our platform.
            By accessing or using Open Course, you agree to these Terms and Conditions. If you do not agree, you must not
            use our platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Open Course is an EdTech platform that offers free courses and certificates based on content sourced from top
            universities like MIT, Stanford, and Harvard. Our mission is to make quality education accessible to everyone.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Eligibility
          </Typography>
          <Typography variant="body1" paragraph>
            To use Open Course, you must:
            <ul>
              <li>Be at least 13 years of age. If you are under 18, you must have parental or guardian consent.</li>
              <li>Agree to comply with these Terms and all applicable laws.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. User Accounts
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Registration:</strong> You must create an account to access certain features. Ensure the information
            provided is accurate and up-to-date.
            <br />
            <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account
            credentials. Notify us immediately of unauthorized access or usage.
            <br />
            <strong>Account Termination:</strong> We reserve the right to suspend or terminate your account for violations
            of these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Content and Usage
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Content Ownership:</strong> All course materials, logos, and content on Open Course belong to their
            respective owners, such as MIT, Stanford, or Harvard, and are used under applicable licensing terms.
            <br />
            <strong>Permitted Use:</strong> You may use the content solely for personal, non-commercial educational
            purposes.
            <br />
            <strong>Prohibited Activities:</strong>
            <ul>
              <li>Republishing, selling, or distributing the course materials without authorization.</li>
              <li>Using our platform for any unlawful or harmful activity.</li>
              <li>Attempting to reverse-engineer or compromise the platform’s security.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Certificates
          </Typography>
          <Typography variant="body1" paragraph>
            Certificates offered by Open Course are for personal use and recognition of course completion. We do not
            guarantee acceptance or recognition of our certificates by any third-party organization or institution.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Third-Party Content
          </Typography>
          <Typography variant="body1" paragraph>
            Open Course aggregates and curates content from multiple sources, including third-party platforms. We do not
            guarantee the accuracy, completeness, or reliability of such content.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All intellectual property on Open Course, including trademarks, logos, and design, remains the exclusive
            property of Open Course and its licensors. Unauthorized use of any intellectual property is prohibited.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            Open Course is provided on an “as-is” basis. To the maximum extent permitted by law, we disclaim all
            warranties, express or implied, regarding the platform. We are not liable for any direct, indirect, or
            consequential damages resulting from the use of our platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            Your use of Open Course is subject to our Privacy Policy, which explains how we collect, use, and protect your
            personal information. By using Open Course, you consent to our privacy practices.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may update these Terms and Conditions from time to time. Changes will be effective immediately upon
            posting. Your continued use of the platform constitutes your acceptance of the revised Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            We may suspend or terminate your access to Open Course at any time for any reason, including violation of
            these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            12. Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            Any disputes arising out of or related to these Terms shall be governed by the laws of Indore, India.
            Disputes shall be resolved through arbitration, and the decision shall be binding.
          </Typography>

          <Typography variant="h6" gutterBottom>
            13. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have questions about these Terms and Conditions, please contact us at:
            <br />
            Email: team@opencourse.live
            <br />
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermCondition;
