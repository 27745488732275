import React, { useState } from "react";
import { ChevronRight, ChevronDown, ChevronLeft, Check } from "lucide-react";

const Sidebar = ({
  lectures,
  activeLecture,
  setActiveLecture,
  activeChapter,
  setActiveChapter,
  setIsQuizVisible,
  onCloseMobile
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsedLectures, setCollapsedLectures] = useState(() => {
    // Initialize all lectures as collapsed
    const initialState = {};
    lectures.forEach(lecture => {
      initialState[lecture.uniq_id] = true;
    });
    return initialState;
  });

  const toggleLectureCollapse = (lectureId) => {
    setCollapsedLectures((prevState) => ({
      ...prevState,
      [lectureId]: !prevState[lectureId],
    }));
  };

  // Handle lecture click
  const handleLectureClick = (lecture) => {
    setActiveLecture(lecture);
    setActiveChapter(lecture.chapters[0]);
    setIsQuizVisible(false);
    toggleLectureCollapse(lecture.uniq_id);
  };

  // Handle chapter click
  const handleChapterClick = (chapter, lecture) => {
    setActiveChapter(chapter);
    setActiveLecture(lecture);
    setIsQuizVisible(false);
  };

  return (
    <aside
      className={`
        bg-white dark:bg-gray-800 shadow-lg
        ${isCollapsed ? 'w-16' : 'w-64 lg:w-80'}
        transition-all duration-300 ease-in-out
        fixed lg:relative 
        inset-0 lg:inset-auto
        z-40
      `}
    >
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center justify-between p-4 border-b dark:border-gray-700 bg-white dark:bg-gray-800 sticky top-0 z-50">
        <h1 className="text-lg font-bold text-gray-800 dark:text-gray-200">
          Course Content
        </h1>
        <button
          onClick={onCloseMobile}
          className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
          aria-label="Close sidebar"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      {/* Desktop Collapse Button */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="hidden lg:block absolute top-4 -right-3 bg-white dark:bg-gray-800 p-1.5 rounded-full shadow-lg z-10 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
        aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
      </button>

      {/* Sidebar Content */}
      <div className={`
        h-[calc(100vh-4rem)] lg:h-screen 
        overflow-y-auto 
        pb-20 
        ${isCollapsed ? '' : 'px-4'}
        scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600
        scrollbar-track-transparent
      `}>
        {!isCollapsed && (
          <>
            {/* Desktop Header - only show on desktop */}
            <div className="hidden lg:block sticky top-0 pt-4 pb-2 bg-white dark:bg-gray-800 z-10">
              <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200 px-2">
                Course Content
              </h2>
            </div>

            <div className="space-y-4 mt-4">
              {lectures.map((lecture) => (
                <div key={lecture.uniq_id}
                  className="rounded-lg bg-gray-50 dark:bg-gray-800/50 border border-gray-100 dark:border-gray-700/50"
                >
                  {/* Lecture Header */}
                  <div
                    className={`
                      flex items-center justify-between p-3 rounded-lg cursor-pointer
                      transition-all duration-200 group
                      ${activeLecture?.uniq_id === lecture.uniq_id
                        ? "bg-blue-50 dark:bg-blue-900/50 shadow-sm"
                        : "hover:bg-gray-100 dark:hover:bg-gray-700/50"
                      }
                    `}
                    onClick={() => handleLectureClick(lecture)}
                    role="button"
                    tabIndex={0}
                  >
                    <div className="flex items-center space-x-2 flex-1 min-w-0">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleLectureCollapse(lecture.uniq_id);
                        }}
                        className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                      >
                        {collapsedLectures[lecture.uniq_id] ? (
                          <ChevronRight className="w-4 h-4 transition-transform" />
                        ) : (
                          <ChevronDown className="w-4 h-4 transition-transform" />
                        )}
                      </button>
                      <h3 className="text-sm font-medium truncate">
                        {lecture.title}
                      </h3>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-xs font-medium text-blue-600 dark:text-blue-400">
                        {lecture.progress}%
                      </span>
                    </div>
                  </div>

                  {/* Progress Bar */}
                  <div className="px-3 pb-2">
                    <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-1.5">
                      <div
                        className="bg-blue-600 dark:bg-blue-400 h-1.5 rounded-full transition-all duration-300"
                        style={{ width: `${lecture.progress}%` }}
                      />
                    </div>
                  </div>

                  {/* Chapters */}
                  <div
                    className={`
                      transition-all duration-200 overflow-hidden
                      ${collapsedLectures[lecture.uniq_id] ? 'max-h-0' : 'max-h-[1000px]'}
                    `}
                  >
                    <div className="space-y-1 p-2">
                      {lecture.chapters.map((chapter) => (
                        <div
                          key={chapter.id}
                          className={`
                            rounded-md transition-all duration-200
                            ${activeChapter?.uniq_id === chapter.id
                              ? "bg-green-50 dark:bg-green-900/50 shadow-sm"
                              : "hover:bg-gray-100 dark:hover:bg-gray-700/50"
                            }
                          `}
                          onClick={() => handleChapterClick(chapter, lecture)}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="p-2 space-y-2">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center space-x-2 flex-1 min-w-0">
                                {chapter.progress === 100 && (
                                  <Check className="w-4 h-4 text-green-500 flex-shrink-0" />
                                )}
                                <span className="text-sm truncate">
                                  {chapter.title}
                                </span>
                              </div>
                              <span className="text-xs font-medium text-green-600 dark:text-green-400">
                                {chapter.progress}%
                              </span>
                            </div>

                            {/* Quiz Status */}
                            <div className={`
                              flex items-center justify-between rounded-md p-1.5
                              ${chapter.quiz?.length > 0
                                ? (chapter.quiz[0].status === "complete"
                                  ? "bg-green-50/50 dark:bg-green-900/30"
                                  : "bg-orange-50/50 dark:bg-orange-900/30")
                                : "bg-gray-50/50 dark:bg-gray-900/30"
                              }
                            `}>
                              <div className="flex items-center space-x-2">
                                <Check className={`w-4 h-4 ${chapter.quiz?.length > 0
                                    ? (chapter.quiz[0].status === "complete"
                                      ? "text-green-500"
                                      : "text-orange-500")
                                    : "text-gray-400"
                                  }`} />
                                <span className="text-xs">
                                  {chapter.quiz?.length > 0
                                    ? (chapter.quiz[0].status === "complete"
                                      ? "Quiz Completed"
                                      : "Take Quiz")
                                    : "Take Quiz"
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Collapsed View */}
        {isCollapsed && (
          <div className="flex flex-col items-center pt-14 space-y-2">
            {lectures.map((lecture, index) => (
              <button
                key={lecture.uniq_id}
                className={`
                  w-8 h-8 rounded-full flex items-center justify-center
                  transition-colors duration-200
                  ${activeLecture?.uniq_id === lecture.uniq_id
                    ? "bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400"
                    : "hover:bg-gray-100 dark:hover:bg-gray-700"
                  }
                `}
                onClick={() => handleLectureClick(lecture)}
                title={lecture.title}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
