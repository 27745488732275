// src/components/Quiz.js
import React, { useState, useEffect } from 'react';
import { getQuiz, validateQuiz } from '../../utils/api';
import { FiCheckCircle, FiXCircle, FiArrowRight, FiRefreshCw } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { useRecoilState } from 'recoil';
import { currentCourseState } from '../../recoil/atoms';
import { getUserProgress } from '../../utils/api';

const Quiz = ({ course, lecture, chapter, chapterId, courseId }) => {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [attempts, setAttempts] = useState({}); // Track attempts per question

  const [, setCurrentCourse] = useRecoilState(currentCourseState);

  const OPTION_KEYS = ['option_1', 'option_2', 'option_3', 'option_4'];
  const MAX_ATTEMPTS = 3; // Maximum attempts per question

  useEffect(() => {
    setLoading(true);
    getQuiz(course, lecture, chapter, chapterId, courseId)
      .then(response => {
        setQuiz([response.data]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching quiz:', error);
        setLoading(false);
      });
    setAttempts({});
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setFeedback(null);
    setShowConfetti(false);
    
  }, [chapterId]);

  useEffect(() => {
    if (selectedOption === null) return;

    setLoading(true);
    validateQuiz(chapterId, selectedOption)
      .then(response => {
        const isCorrect = response.data.is_correct;
        setFeedback(isCorrect ? 'correct' : 'incorrect');
        
        // Update attempts count for current question
        if (!isCorrect) {
          setAttempts(prev => ({
            ...prev,
            [currentQuestionIndex]: (prev[currentQuestionIndex] || 0) + 1
          }));
        }

        if (isCorrect) {
          setShowConfetti(true);
          getUserProgress().then(response => {
            const userProgress = response.data.user_progress;
            const updatedCourse = userProgress.course.find(c => c.id.toString() === userProgress.current_course);
            if (updatedCourse) {
              setCurrentCourse(updatedCourse);
            }
          })
          setTimeout(() => setShowConfetti(false), 3000);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error validating quiz:', error);
        setLoading(false);
      });
  }, [selectedOption]);

  const retryQuestion = () => {
    setFeedback(null);
    setSelectedOption(null);
  };

  const nextQuestion = () => {
    setFeedback(null);
    setSelectedOption(null);
    setCurrentQuestionIndex(prev => prev + 1);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  if (!quiz) return null;

  const currentQuestion = quiz[currentQuestionIndex];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8"
    >
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-3">
          <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
            Question {currentQuestionIndex + 1} of {quiz.length}
          </span>
          <span className="text-sm font-bold text-blue-600 dark:text-blue-400">
            {Math.round(((currentQuestionIndex + 1) / quiz.length) * 100)}%
          </span>
        </div>
        <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-3">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${((currentQuestionIndex + 1) / quiz.length) * 100}%` }}
            transition={{ duration: 0.5 }}
            className="bg-blue-500 h-3 rounded-full"
          ></motion.div>
        </div>
      </div>

      <motion.h3
        key={currentQuestionIndex}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-2xl font-bold mb-8 text-gray-800 dark:text-white leading-relaxed"
      >
        {currentQuestion.question}
      </motion.h3>

      <div className="space-y-4">
        <AnimatePresence mode="wait">
          {OPTION_KEYS.map((optionKey, index) => {
            const optionNumber = index + 1;
            return (
              <motion.button
                key={optionKey}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                onClick={() => {
                  if (!feedback) {
                    setSelectedOption(index + 1);
                  }
                }}
                disabled={feedback !== null}
                className={`
                  relative w-full text-left p-2 rounded-xl transition-all duration-300 transform
                  ${selectedOption === (index + 1)
                    ? 'bg-blue-500 text-white shadow-xl scale-102'
                    : 'bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 border-2 border-gray-200 dark:border-gray-600 hover:shadow-md'
                  }
                  ${feedback !== null ? 'cursor-not-allowed' : 'hover:scale-102'}
                  group
                `}
              >
                <div className="flex items-center space-x-4">
                  <span className={`
                    w-8 h-8 flex items-center justify-center rounded-full text-sm font-bold
                    ${selectedOption === (index + 1)
                      ? 'bg-white text-blue-500'
                      : 'bg-blue-100 dark:bg-gray-600 text-blue-500 dark:text-white'
                    }
                  `}>
                    {String.fromCharCode(65 + index)}
                  </span>
                  <span className="flex-1">{currentQuestion[optionKey]}</span>
                </div>
              </motion.button>
            );
          })}
        </AnimatePresence>
      </div>

      {/* Feedback Section */}
      <AnimatePresence>
        {feedback && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`mt-8 p-6 rounded-xl shadow-lg ${feedback === 'correct'
              ? 'bg-green-50 dark:bg-green-900/30 border-l-4 border-green-500'
              : 'bg-red-50 dark:bg-red-900/30 border-l-4 border-red-500'
              }`}
          >
            {feedback === 'correct' ? (
              <div className="flex items-center">
                <FiCheckCircle className="w-8 h-8 text-green-500 mr-4" />
                <div>
                  <h4 className="text-lg font-bold text-green-800 dark:text-green-200">Excellent work! 🎉</h4>
                  <p className="text-green-700 dark:text-green-300">You've mastered this concept!</p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <FiXCircle className="w-8 h-8 text-red-500 mr-4" />
                  <div>
                    <h4 className="text-lg font-bold text-red-800 dark:text-red-200">Keep trying!</h4>
                    <p className="text-red-700 dark:text-red-300">
                      Attempt {(attempts[currentQuestionIndex] || 0)} of {MAX_ATTEMPTS}
                    </p>
                  </div>
                </div>
                {(attempts[currentQuestionIndex] || 0) < MAX_ATTEMPTS && (
                  <button
                    onClick={retryQuestion}
                    className="w-full bg-red-100 hover:bg-red-200 text-red-700 px-4 py-2 rounded-lg 
                             transition-colors duration-200 flex items-center justify-center space-x-2"
                  >
                    <span>Try Again</span>
                    <FiRefreshCw className="w-4 h-4" />
                  </button>
                )}
                {(attempts[currentQuestionIndex] || 0) >= MAX_ATTEMPTS && (
                  <div className="text-red-700 dark:text-red-300 text-center">
                    <p>Maximum attempts reached. Let's move to the next question.</p>
                  </div>
                )}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Next Button - Show when answer is correct or max attempts reached */}
      {(feedback === 'correct' || (attempts[currentQuestionIndex] || 0) >= MAX_ATTEMPTS) && 
       currentQuestionIndex < quiz.length - 1 && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={nextQuestion}
          className="mt-8 w-full bg-blue-500 text-white px-8 py-4 rounded-xl shadow-xl hover:bg-blue-600 
                   transform transition-all duration-300 hover:scale-102 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50
                   flex items-center justify-center space-x-2 font-bold text-lg"
        >
          <span>Next Question</span>
          <FiArrowRight className="w-5 h-5" />
        </motion.button>
      )}

      {showConfetti && (
        <div className="fixed inset-0 pointer-events-none z-50">
          {/* Add confetti animation here if desired */}
        </div>
      )}
    </motion.div>
  );
};

export default Quiz;
