import { atom } from 'recoil';

// export const selectedPlanState = atom({
//   key: 'selectedPlanState',
//   default: null,
// });

// export const plansState = atom({
//   key: 'plansState',
//   default: [],
// }); 

export const progressState = atom({
  key: 'progressState',
  default: [],
}); 

export const currentCourseState = atom({
  key: 'currentCourseState',
  default: [],
}); 