// src/utils/api.js
import axios from 'axios';
import UserProgress from '../model/userProgressModel';

const host = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: host,
});

export const enrollCourse = async (courseId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await api.post(`/enroll/${courseId}`, null, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return new UserProgress(response.data);
  } catch (error) {
    console.error('Error enrolling in course:', error);
    throw error;
  }
};

export const updateProgress = (chapterId) => {
  return api.post(`/api/v1/progress/${chapterId}`);
};

export const validateQuiz = async (chapterId, correctOption) => {
  const token = localStorage.getItem('token');
  return api.get(`/validate/${chapterId}/option_${correctOption}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const searchDescriptionV2 = async () => {
  try {
    const response = await api.get('/api/search?data_type=descriptionV2');
    return response.data;
  } catch (error) {
    console.error('Error fetching search data:', error);
    throw error;
  }
};

export const getContentByCourseId = async (courseId) => {
  try {
    const response = await api.get(`/api/content/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching content:', error);
    throw error;
  }
};

export const registerAPI = async ({ email, password, name }) => {

  return fetch(host + '/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      name,
      auth_provider: 'email',
    }),
  });
};

export const loginAPI = async ({ email, password }) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'password');
  params.append('username', email);
  params.append('password', password);

  return fetch(host + '/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    },
    body: params,
  });
};


export const fetchSearchResults = async (searchUrl) => {
  try {
    const response = await api.get(searchUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;
  }
};

export const updateProgressAPI = async (courseId, lectureId, chapterId, progress) => {
  const token = localStorage.getItem('token');
  return api.put(`/progress/${courseId}/${lectureId}/${chapterId}?progress=${progress}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getQuiz = async (course, lecture, chapter, chapter_id, course_id) => {
  const token = localStorage.getItem('token')
  return api.post(`/quiz`, {
    chapter_id,
    course,
    lecture,
    chapter,
    course_id,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const getUserProgress = async () => {
  const token = localStorage.getItem('token');
  return api.get('/progress', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// @router.put("/current-course/{courseId}")
export const updateCurrentCourse = async (courseId) => {
  const token = localStorage.getItem('token');
  return api.put(`/current-course/${courseId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const updateToken = async (refreshToken) => {
  return api.post(`/refresh-token?refresh_token=${refreshToken}`);
}

export const subscribe = async (email) => {
  return api.post('/subscribe', { email });
}


export const loginWithLinkedInAPI = async (code) => {
  return api.post('/auth-linkedin', {
    token: code,
  });
};

export const loginWithGoogleAPI = async (code) => {
  return api.post('/auth-google', {
    token: code,
  });
};
