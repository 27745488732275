// src/components/navigation/DesktopMenu.js
import React from 'react';
import { Link } from 'react-router-dom';


const DesktopMenu = ({ navigationItems, isLoggedIn, handleLogout, openSignIn, location }) => (
  <div className="hidden md:flex items-center flex-1 justify-end">
    <div className="flex space-x-4">
      {navigationItems.map((item) => (
        item.isExternal ? (
          <a
            key={item.path}
            href={item.path}
            className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100"
          >
            {item.icon}
            <span className="ml-2">{item.label}</span>
          </a>
        ) : (
          <Link
            key={item.path}
            to={item.path}
            className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${location.pathname === item.path
              ? 'bg-blue-100 text-blue-600'
              : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            {item.icon}
            <span className="ml-2">{item.label}</span>
          </Link>
        )
      ))}
      {isLoggedIn ? (
        <>
          <Link
            to="/profile"
            className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100"
          >
            Profile
          </Link>
          <button
            onClick={handleLogout}
            className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100"
          >
            Logout
          </button>
        </>
      ) : (
        <button
          onClick={openSignIn}
          className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100"
        >
          Sign In
        </button>
      )}
    </div>
  </div>
);

export default DesktopMenu;
