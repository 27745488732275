import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";


import SchoolIcon from "@mui/icons-material/School"; 
import SearchIcon from "@mui/icons-material/Search"; 
import StarIcon from "@mui/icons-material/Star"; 
import WorkIcon from "@mui/icons-material/Work"; 
import HomeIcon from "@mui/icons-material/Home"; 
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"; 
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business"; 

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import courseData from "../courses/FeaturedCourses.json"; 
import CallToAction from "./components/CallToAction"; 


import SubscribeMeModal from "../SubscribeMeModal";

const HomePage = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [featuredCourses, setFeaturedCourses] = useState([]);

  useEffect(() => {
    console.log("HomePage mounted");
    if (courseData && courseData.courses) {
      setFeaturedCourses(courseData.courses.slice(0, 6));
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (!courseData || !courseData.courses) return;

    switch (newValue) {
      case 0: // Career Boost
        setFeaturedCourses(
          courseData.courses
            .filter(
              (course) =>
                course.category === "Computer Science" ||
                course.category === "Business"
            )
            .slice(0, 6)
        );
        break;
      case 1: // Applied Science & Social Studies
        setFeaturedCourses(
          courseData.courses
            .filter(
              (course) =>
                course.category === "Psychology" ||
                course.category === "Physics" ||
                course.category === "Economics"
            )
            .slice(0, 6)
        );
        break;
      case 2: // Advanced Science & Technology
        setFeaturedCourses(
          courseData.courses
            .filter(
              (course) =>
                course.category === "Mathematics" ||
                course.category === "Neuroscience" ||
                course.category === "Blockchain"
            )
            .slice(0, 6)
        );
        break;
      default:
        setFeaturedCourses(courseData.courses.slice(0, 6));
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const topPartners = [
    { name: "MIT", logo: "MIT-Logo.wine.svg", url: "/courses?search?q=MIT" },
    {
      name: "Harvard",
      logo: "Harvard.svg.png",
      url: "/courses?search?q=Harvard",
    },
    {
      name: "Stanford",
      logo: "StanfordUniversity.png",
      url: "/courses?search?q=Stanford",
    },
    // { name: 'Google', logo: '/api/placeholder/100/50' },
    // { name: 'IBM', logo: '/api/placeholder/100/50' },
  ];

  const popularTopics = [
    {
      title: "Computer Science",
      url: "/courses?categories=Computer+Science",
    },
    {
      title: "Economics",
      url: "/courses?categories=Economics",
    },
    {
      title: "Biology",
      url: "/courses?categories=Biology",
    },
    {
      title: "Mathematics",
      url: "/courses?categories=Mathematics",
    },
    {
      title: "Physics",
      url: "/courses?categories=Physics",
    },
  ];

  const careerData = [
    { name: "", value: 1 },
    { name: "", value: 2 },
    { name: "", value: 4 },
    { name: "", value: 8 },
    { name: "", value: 16 },
    { name: "", value: 32 },
  ];

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["Home", "Courses", "My Learning", "For Business"].map(
          (text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index === 0 ? (
                  <HomeIcon />
                ) : index === 1 ? (
                  <LibraryBooksIcon />
                ) : index === 2 ? (
                  <AccountCircleIcon />
                ) : (
                  <BusinessIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ backgroundColor: "#f5f5f5" }}>
        {/* Hero Section */}
        <Box
          sx={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('4924562375_c3dd0d4e3c_b.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            py: 12,
          }}
        >
          <Container maxWidth="md">
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              fontWeight="bold"
              color="white"
              textAlign="center"
            >
              Unlock Your Potential
            </Typography>
            <Typography
              variant="h5"
              component="p"
              gutterBottom
              color="white"
              textAlign="center"
            >
              Discover world-class courses and transform your career with Open
              Course
            </Typography>

            <div className="flex justify-center my-8">
              <button
                onClick={() => navigate("/courses")}
                className="flex items-center justify-center w-full max-w-xs px-6 py-4 bg-blue-600 text-white text-lg font-semibold rounded-md shadow-lg hover:bg-blue-700 transition-all duration-200 ease-in-out transform hover:scale-105 focus:outline-none"
              >
                <span className="mr-3">Explore Courses</span>
                <SearchIcon className="text-white" />
              </button>
            </div>
          </Container>
        </Box>

        {/* Partners Section */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            textAlign="center"
          >
            Learn from world-class universities and companies
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {topPartners.map((partner, index) => (
              <Grid item key={index}>
                <img
                  onClick={() => navigate(partner.url)}
                  src={partner.logo}
                  alt={partner.name}
                  style={{ width: 200, height: 100, objectFit: "contain" }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Featured Courses */}
        <Box>
          <Typography
            marginTop={10}
            variant="h4"
            component="h3"
            gutterBottom
            fontWeight="bold"
            textAlign="center"
            color="primary"
          >
            Featured on Open Course
          </Typography>
          <Box>
            <Container maxWidth="lg">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                sx={{ mb: 4 }}
              >
                <Tab
                  label="Career Boost"
                  onClick={() =>
                    setFeaturedCourses(
                      courseData.courses
                        .filter(
                          (course) =>
                            course.category === "Computer Science" ||
                            course.category === "Business"
                        )
                        .slice(0, 6)
                    )
                  }
                />
                <Tab
                  label="Applied Science & Social Studies"
                  onClick={() =>
                    setFeaturedCourses(
                      courseData.courses
                        .filter(
                          (course) =>
                            course.category === "Psychology" ||
                            course.category === "Physics" ||
                            course.category === "Economics"
                        )
                        .slice(0, 6)
                    )
                  }
                />
                <Tab
                  label="Advanced Science & Technology"
                  onClick={() =>
                    setFeaturedCourses(
                      courseData.courses
                        .filter(
                          (course) =>
                            course.category === "Mathematics" ||
                            course.category === "Neuroscience" ||
                            course.category === "Blockchain"
                        )
                        .slice(0, 6)
                    )
                  }
                />
              </Tabs>
              <Grid container spacing={4} justifyContent="center">
                {featuredCourses.map((course, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      elevation={3}
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        image={course.image_url || "default-image-url.jpg"}
                        alt={course.title}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          variant="h6"
                          component="h4"
                          gutterBottom
                          fontWeight="bold"
                        >
                          {course.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {course.university}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mt: 2 }}
                          onClick={() =>
                            navigate(`/course-detail/${course.id}`)
                          }
                        >
                          Enroll Now
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </Box>

        {/* About Us Section */}
        <Box sx={{ py: 14 }}>
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottom
                  fontWeight="bold"
                  color="primary"
                  my={5}
                >
                  About Open Course
                </Typography>
                <Typography variant="body1" paragraph>
                  Open Course is a revolutionary online learning platform that
                  brings together the world's best educational institutions and
                  industry experts. Our mission is to provide accessible,
                  high-quality education to learners worldwide.
                </Typography>
                <Typography variant="body1" paragraph>
                  We believe that education should be accessible to everyone,
                  everywhere. Through our platform, students can access courses
                  from prestigious universities and gain valuable skills that
                  are relevant in today's rapidly evolving job market.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/about-us")}
                  sx={{ mt: 2 }}
                >
                  Learn More About Us
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                  alt="About Open Course"
                  sx={{
                    width: "100%",
                    maxHeight: 400,
                    objectFit: "cover",
                    borderRadius: 2,
                    boxShadow: 3,
                    display: "block",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Main Content */}
        <Box>
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Grid container spacing={6}>
              {/* Left Column */}
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h4"
                  component="h3"
                  gutterBottom
                  fontWeight="bold"
                >
                  Chart Your Course to Success
                </Typography>
                <Typography variant="body1" paragraph>
                  At Open Course, we believe in the power of education to
                  transform lives and careers. Whether you're looking to sharpen
                  your skills, change careers, or advance in your current role,
                  we have the courses and programs to help you achieve your
                  goals.
                </Typography>
                <Box sx={{ height: 300, mb: 4 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={careerData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
                <Grid container spacing={2} justifyContent="center">
                  {[
                    "Explore Courses",
                    "Start a Degree",
                    "Get Certified",
                    "Learn for Business",
                  ].map((text, index) => (
                    <Grid item xs={6} sm={3} key={index} sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ textTransform: "none" }}
                      >
                        {text}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {/* Right Column */}
              <Grid item xs={12} md={4}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      fontWeight="bold"
                    >
                      Why Choose Open Course?
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <StarIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="World-class instructors" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <WorkIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Industry-relevant skills" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <SchoolIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Flexible learning paths" />
                      </ListItem>
                    </List>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => {
                        navigate("/courses");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Start Learning Now
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Regular Scrolling Sections */}
        <Box sx={{ bgcolor: "background.default", py: 8 }}>
          {/* Popular Topics */}
          <Container maxWidth="lg" sx={{ mb: 8 }}>
            <Typography
              variant="h4"
              component="h3"
              gutterBottom
              fontWeight="bold"
              textAlign="center"
              marginBottom={6}
            >
              Explore Popular Topics
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {popularTopics.map((topic, index) => (
                <Grid item key={index}>
                  <Chip
                    label={topic.title}
                    onClick={() => navigate(topic.url)}
                    clickable
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </Container>

          {/* Call to Action */}
          <Container marginTop={10} maxWidth="lg">
            <CallToAction />
          </Container>
        </Box>
      </Box>
      {/* <SubscribeMeModal /> */}
    </>
  );
};

export default HomePage;
