import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      <Typography variant="h3" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Effective Date: 15th February 2025
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography paragraph>
          Open Course provides free access to educational content from top
          universities, including MIT, Stanford, and Berkeley. These Terms
          govern your use of our platform, including all services, features, and
          resources offered through opencoursehub.online ("Platform").
        </Typography>
        <Typography variant="h5" gutterBottom>
          2. Acceptance of Terms
        </Typography>
        <Typography paragraph>
          By accessing or using Open Course, you confirm that you have read,
          understood, and agree to these Terms. If you do not agree to these
          Terms, you must stop using the platform immediately.
        </Typography>
        <Typography variant="h5" gutterBottom>
          3. Changes to Terms
        </Typography>
        <Typography paragraph>
          We may modify these Terms from time to time. The most recent version
          will always be available on our website. Continued use of Open Course
          after updates signifies your acceptance of the revised Terms.
        </Typography>
        <Typography variant="h5" gutterBottom>
          4. Eligibility
        </Typography>
        <Typography paragraph>
          - You must be at least 13 years old to use Open Course.
          <br />- If you are under 18, you must have permission from a parent or
          guardian to use our services.
          <br />- You agree to comply with all applicable laws and regulations.
        </Typography>
        <Typography variant="h5" gutterBottom>
          5. User Accounts
        </Typography>
        <Typography paragraph>
          Some features may require you to create an account. You are
          responsible for maintaining the confidentiality of your account
          information and for all activities that occur under your account.
        </Typography>
        <Typography variant="h5" gutterBottom>
          6. Content Usage
        </Typography>
        <Typography paragraph>
          All content on Open Course is for personal, educational, and
          non-commercial use only. You may not reproduce, distribute, or modify
          any content without prior permission.
        </Typography>
        <Typography variant="h5" gutterBottom>
          7. Prohibited Activities
        </Typography>
        <Typography paragraph>
          When using Open Course, you agree not to:
          <br />- Use the platform for unlawful purposes.
          <br />- Attempt to hack, disrupt, or damage the platform.
          <br />- Share offensive, inappropriate, or misleading content.
          <br />- Violate any intellectual property rights.
        </Typography>
        <Typography variant="h5" gutterBottom>
          8. Intellectual Property
        </Typography>
        <Typography paragraph>
          Open Course and its associated content, logos, and materials are
          protected by intellectual property laws. You may not use Open Course’s
          branding or materials without prior written consent.
        </Typography>
        <Typography variant="h5" gutterBottom>
          9. Third-Party Content and Links
        </Typography>
        <Typography paragraph>
          Open Course may include links to third-party websites. We are not
          responsible for the content or practices of these websites. Accessing
          third-party content is at your own risk.
        </Typography>
        <Typography variant="h5" gutterBottom>
          10. Limitation of Liability
        </Typography>
        <Typography paragraph>
          Open Course is provided "as is" without warranties of any kind. We are
          not liable for any errors, omissions, or interruptions in service.
        </Typography>
        <Typography variant="h5" gutterBottom>
          11. Termination
        </Typography>
        <Typography paragraph>
          We reserve the right to suspend or terminate your access to Open
          Course at any time, for any reason, including violations of these
          Terms.
        </Typography>
        <Typography variant="h5" gutterBottom>
          12. Governing Law
        </Typography>
        <Typography paragraph>
          These Terms are governed by and construed in accordance with the laws
          of Madhya Pradesh, India, without regard to its conflict of law
          principles.
        </Typography>
        <Typography variant="h5" gutterBottom>
          13. Privacy
        </Typography>
        <Typography paragraph>
          Your use of Open Course is also governed by our Privacy Policy. Please
          review it to understand how we collect and use your data.
        </Typography>
        <Typography variant="h5" gutterBottom>
          14. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about these Terms, please contact us at:
          <br />
          <strong>Email:</strong> support@opencoursehub.online
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
