import React from "react";

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-9xl font-bold">404</h1>
      <h2 className="text-4xl">Not Found</h2>
      <p className="text-xl">The page you are looking for does not exist.</p>
    </div>
  );
};

export default Error;