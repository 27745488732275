// src/components/CoursePlayer.js
import React from 'react';
import ReactPlayer from 'react-player';
import { useRecoilState } from 'recoil';
import { currentCourseState } from '../../recoil/atoms';
import { updateProgressAPI, getUserProgress } from '../../utils/api';

const CoursePlayer = ({ courseId, lectureId, chapterId, videoUrl, setShowCompletionMessage }) => {

  const [, setCurrentCourse] = useRecoilState(currentCourseState);

  const updateProgress = () => {
    updateProgressAPI(courseId, lectureId, chapterId, 100)
      .then(async () => {
        const response = await getUserProgress();
        const userProgress = response.data.user_progress;
        const updatedCourse = userProgress.course.find(c => c.id.toString() === userProgress.current_course);
        if (updatedCourse) {
          setCurrentCourse(updatedCourse);
        }
        setShowCompletionMessage(true);
      })
      .catch(error => {
        console.error('Error updating progress:', error);
      });
  }


  console.log('videoUrl', videoUrl);
  return (
    <div className="w-full bg-black mb-8">
      <ReactPlayer
        url={videoUrl}
        controls
        width="100%"
        height="500px"
        // progress={30000} //every 30 seconds
        onEnded={() => {
          updateProgress()
        }}
      />
    </div>
  );
};

export default CoursePlayer;
