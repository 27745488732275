// userProgressModel.js

// Response Model
class UserProgress {
    constructor(data) {
      this.message = data.message;
      this.userProgress = {
        currentCourse: data.user_progress.current_course,
        userId: data.user_progress.user_id,
        courses: data.user_progress.course.map(course => ({
          id: course.id,
          img: course.img,
          tags: course.tags,
          category: course.category,
          oldTitle: course.old_title,
          professor: course.professor,
          university: course.university,
          subcategory: course.subcategory,
          existingTitle: course.existing_title,
          whatYouLearn: course.what_You_Learn,
          skillsYouGain: course.skills_You_Gain,
          rephrasedDescription: course.rephrased_description,
          prerequisites: course.prerequisites_for_course,
          facts: course.some_Facts_About_The_Subject,
          lectures: course.lectures.map(lecture => ({
            uniq_id: lecture.uniq_id,
            title: lecture.title,
            video: lecture.video,
            duration: lecture.duration,
            thumbnail: lecture.thumbnail,
            chapters: lecture.chapters.map(chapter => ({
              startTime: chapter.start_time,
              title: chapter.title,
              endTime: chapter.end_time,
              uniq_id: chapter.uniq_id,
              status: chapter.status,
              progress: chapter.progress,
              quiz: chapter.quiz,
              videoUrl: chapter.video_url,
            })),
            status: lecture.status,
            progress: lecture.progress,
          })),
          status: course.status,
          progress: course.progress,
        })),
      };
    }
  }
  
  module.exports = UserProgress;
  