import React, { useState } from 'react';
import AuthForm from './AuthForm';
import AuthHeader from './AuthHeader';

const AuthModal = ({ isOpen, onClose, defaultMode = 'signin' }) => {
  const [mode, setMode] = useState(defaultMode);

  if (!isOpen) return null;

  const toggleMode = () => setMode((prev) => (prev === 'signin' ? 'signup' : 'signin'));

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="p-6">
          <AuthHeader mode={mode} />
          <AuthForm mode={mode} onClose={onClose} />
          <div className="mt-6 text-center text-sm">
            <span className="text-gray-600">
              {mode === 'signin' ? "Don't have an account? " : 'Already have an account? '}
            </span>
            <button onClick={toggleMode} className="text-blue-600 hover:text-blue-500 font-medium">
              {mode === 'signin' ? 'Sign up' : 'Sign in'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
