import React, { useState, useEffect } from "react";
import faq from "./faq.json";
import { useParams } from "react-router-dom";
import {
  GraduationCap,
  CheckCircle,
  Info,
  Video,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getContentByCourseId } from "../utils/api";
import CourseDetailSkeleton from './CourseDetailSkeleton';

const CourseDetail = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");
  const [openLectures, setOpenLectures] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const contentData = await getContentByCourseId(courseId);

        const combinedCourseData = {
          ...contentData.json_data,
          image_url: contentData.json_data.img,
          title: contentData.json_data.rephrased_title || contentData.json_data.existing_title,
          description: contentData.json_data.rephrased_description,
          source: contentData.json_data.source || contentData.json_data.channel_name || contentData.json_data.university,
          modules: contentData.json_data.lectures?.map((lecture) => ({
            id: lecture.uniq_id,
            title: lecture.title,
            duration: `${Math.floor(lecture.duration / 60)} minutes`,
            video: lecture.video,
            thumbnail: lecture.thumbnail,
            chapters: lecture.chapters || [],
          })) || [],
        };

        setCourse(combinedCourseData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    if (courseId) {
      fetchCourseData();
    }
  }, [courseId]);

  const toggleLecture = (index) => {
    setOpenLectures((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (!course) {
    return <CourseDetailSkeleton />;

    // return (
    //   <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    //     <div className="text-xl text-gray-600">Loading course...</div>
    //   </div>
    // );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Course Header */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8 flex flex-col lg:flex-row">
          {course.image_url ? (
            <img
              src={course.image_url}
              alt={course.title}
              className="w-full lg:w-1/3 h-auto rounded-lg mb-4 lg:mb-0 lg:mr-6"
            />
          ) : (
            <div className="w-full lg:w-1/3 h-48 flex items-center justify-center bg-gray-200 rounded-lg mb-4 lg:mb-0 lg:mr-6">
              <span className="text-gray-600 text-lg">No Image Available</span>
            </div>
          )}

          <div className="flex-1">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">{course.title}</h1>
            <div className="flex items-center mb-4">
              <span className="text-sm text-gray-500">{course.university}</span>
              <span className="mx-2">•</span>
              <span className="text-sm text-gray-500">{course.category}</span>
              {course.professor && (
                <>
                  <span className="mx-2">•</span>
                  <span className="text-sm text-gray-500">Prof. {course.professor}</span>
                </>
              )}
            </div>

            <p className="text-gray-600 mb-6">{course.description}</p>
            <button
              onClick={() =>
                navigate("/plan-selection", {
                  state: { courseId: course.id, CourseTitle: course.title },
                })
              }
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
            >
              Enroll Now
            </button>
          </div>
        </div>

        <div className="flex space-x-4 border-b border-gray-200 mb-8">
          {["overview", "syllabus", "FAQ"].map((tab) => (
            <button
              key={tab}
              className={`py-2 px-4 transition-colors duration-300 ${activeTab === tab
                ? "border-b-2 border-blue-600 font-semibold text-blue-600"
                : "text-gray-500 hover:text-blue-600"
                }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {activeTab === "FAQ" && (
          <div className="bg-white rounded-lg shadow-lg p-6 mt-8 transition-all duration-500 ease-in-out transform">
            <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>
            <div className="space-y-4">
              {
              faq.slice(0,3)
              .map((item, index) => (
                <div key={index} className="border-b border-gray-200 pb-4 mb-4">
                  <h3
                    className="cursor-pointer transition duration-200 bold ease-in-out"
                    onClick={() => {
                      const answerElement = document.getElementById(`answer-${index}`);
                      answerElement.classList.toggle('hidden');
                      answerElement.classList.toggle('max-h-0');
                      answerElement.classList.toggle('opacity-0');
                      answerElement.classList.toggle('opacity-100');
                    }}
                  >
                    Q: {item.question}
                  </h3>
                  <p id={`answer-${index}`} className="hidden max-h-0 mt-4 opacity-0 transition-all duration-200 ease-in-out">
                    A: {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "overview" && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-3">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-2xl font-bold mb-6">Course Overview</h2>
                <p className="text-gray-600 mb-6">{course.description}</p>

                {course.what_You_Learn && course.what_You_Learn.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-4">
                      What You'll Learn
                    </h3>
                    <ul className="space-y-2">
                      {course.what_You_Learn.map((item, index) => (
                        <li key={index} className="flex items-start space-x-3">
                          <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {course.skills_You_Gain && course.skills_You_Gain.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-4">
                      Skills You'll Gain
                    </h3>
                    <ul className="space-y-2">
                      {course.skills_You_Gain.map((item, index) => (
                        <li key={index} className="flex items-start space-x-3">
                          <GraduationCap className="h-6 w-6 text-blue-500 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {course.some_Facts_About_The_Subject && course.some_Facts_About_The_Subject.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-4">
                      Facts About This Subject
                    </h3>
                    <ul className="space-y-2">
                      {course.some_Facts_About_The_Subject.map((item, index) => (
                        <li key={index} className="flex items-start space-x-3">
                          <Info className="h-6 w-6 text-purple-500 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "syllabus" && (
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-bold mb-6">Course Content</h2>
            <div className="space-y-4">
              {course.modules && course.modules.length > 0 ? (
                course.modules.map((module, index) => (
                  <div key={module.id || index} className="border rounded-lg p-4">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => toggleLecture(index)}
                    >
                      <h3 className="text-lg font-semibold">{module.title}</h3>
                      <div className="flex items-center space-x-4">
                        <span className="text-sm text-gray-500">
                          {module.duration || "Duration not available"}
                        </span>
                        {openLectures[index] ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    </div>

                    {openLectures[index] && (
                      <div className="mt-4 pl-4 space-y-3">
                        {module.content && (
                          <p className="text-gray-600">{module.content}</p>
                        )}
                        {module.chapters && module.chapters.length > 0 && (
                          <div className="space-y-2 mt-2">
                            {module.chapters.map((chapter, idx) => (
                              <div key={idx} className="text-sm bg-gray-100 px-3 py-2 rounded flex items-center">
                                <Video className="w-4 h-4 mr-2" />
                                {chapter.title}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-center py-8">
                  <p className="text-gray-500">
                    No content available for this course yet.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="mt-8 text-center text-sm text-gray-500">
          {course.source && <p>Content powered by {course.source}</p>}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
