import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const CancellationRefundPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ padding: '2rem 0' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        Cancellation and Refund Policy
      </Typography>
      
      <Typography variant="body1" paragraph>
        At Open Course, we prioritize our users' experience and strive to provide high-quality educational resources. This policy outlines the terms for cancellations and refunds for the services offered by Open Course.
      </Typography>

      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          1. Subscription Cancellation
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Monthly Subscriptions:</strong> You may cancel your subscription at any time. Cancellations will be effective at the end of the current billing cycle. No partial refunds will be provided for the remaining duration of the billing period.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Annual Subscriptions:</strong> Cancellations are allowed at any time, but refunds will be prorated based on the time remaining in the subscription. A cancellation fee of 10% of the remaining subscription value may apply.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          2. Course Enrollment Cancellation
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Self-Paced Courses:</strong> If you cancel within 7 days of enrollment and have completed less than 10% of the course content, you are eligible for a full refund. No refunds will be provided after 7 days or if more than 10% of the course has been completed.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Live Courses and Workshops:</strong> Cancellations made 48 hours before the start of the course will receive a full refund. Cancellations made within 48 hours of the course start date are non-refundable.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          3. Exceptional Cases for Refunds
        </Typography>
        <Typography variant="body1" paragraph>
          We may offer refunds in exceptional cases such as duplicate payments, technical issues preventing access to the course content, or discontinuation of a course by Open Course. Requests will be reviewed on a case-by-case basis.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          4. Refund Process
        </Typography>
        <Typography variant="body1" paragraph>
          Refunds will be processed to the original payment method within 7–10 business days after approval. You will receive an email confirmation once your refund has been processed.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          5. How to Request a Cancellation or Refund
        </Typography>
        <Typography variant="body1" paragraph>
          To request a cancellation or refund, please contact our support team at <strong>support@opencoursehub.online</strong> with your registered email and course details. Our team will review and respond to your request within 3 business days.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          6. Non-Refundable Services
        </Typography>
        <Typography variant="body1" paragraph>
          The following services are non-refundable: personalized mentorship sessions, special offers and discounted courses, and one-time purchases of learning resources (e.g., e-books, premium templates).
        </Typography>
      </Box>

      <Typography variant="body2" paragraph>
        Open Course reserves the right to modify this Cancellation and Refund Policy at any time. Changes will be communicated on our website.
      </Typography>
    </Container>
  );
};

export default CancellationRefundPolicy;
