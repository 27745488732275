import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Sidebar from '../components/learner/Sidebar';
import MainContent from '../components/learner/MainContent';
import CourseTitle from '../components/learner/CourseTitle';

import { useRecoilState } from 'recoil';
import { progressState, currentCourseState } from '../recoil/atoms';

import { getUserProgress } from '../utils/api';

const LearnerPage = () => {
  const [progress, setProgress] = useRecoilState(progressState);
  const [currentCourse, setCurrentCourse] = useRecoilState(currentCourseState);
  
  const [activeLecture, setActiveLecture] = useState(null);
  const [activeChapter, setActiveChapter] = useState(null);
  const [isQuizVisible, setIsQuizVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Memoize the function to find current course
  const findCurrentCourse = useCallback((userProgress) => {
    return userProgress.course.find(c => c.id.toString() === userProgress.current_course
    );
  }, []);

  // Memoize the initialization of active lecture and chapter
  const initializeActiveContent = useCallback((courseData) => {
    if (courseData?.lectures?.length > 0 && 
        courseData.lectures[0].chapters?.length > 0) {
      setActiveLecture(courseData.lectures[0]);
      setActiveChapter(courseData.lectures[0].chapters[0]);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchUserProgress = async () => {
      try {
        setIsLoading(true);
        const response = await getUserProgress();
        
        if (!isMounted) return;

        const userProgress = response.data.user_progress;
        const currentCourseData = findCurrentCourse(userProgress);
        
        if (currentCourseData) {
          setCurrentCourse(currentCourseData);
          initializeActiveContent(currentCourseData);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message || 'Failed to fetch user progress');
          console.error('Error fetching user progress:', error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchUserProgress();

    return () => {
      isMounted = false;
    };
  }, [findCurrentCourse, initializeActiveContent, setCurrentCourse]);

  // Memoize the sidebar props
  const sidebarProps = useMemo(() => ({
    lectures: currentCourse?.lectures || [],
    activeLecture,
    setActiveLecture,
    activeChapter,
    setActiveChapter,
    setIsQuizVisible,
    onCloseMobile: () => setIsSidebarOpen(false)
  }), [currentCourse?.lectures, activeLecture, activeChapter]);

  // Memoize the main content props
  const mainContentProps = useMemo(() => ({
    course: currentCourse?.rephrased_title,
    courseId: currentCourse?.id,
    activeChapter,
    activeLecture,
    isQuizVisible,
    setIsQuizVisible
  }), [currentCourse?.rephrased_title, currentCourse?.id, activeChapter, activeLecture, isQuizVisible]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
        <div className="text-red-500 text-lg mb-2">⚠️ {error}</div>
        <button 
          onClick={() => window.location.reload()} 
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!currentCourse || !currentCourse.lectures) {
    return (
      <div className="flex items-center justify-center min-h-screen p-4 text-center text-gray-600 dark:text-gray-300">
        No course data available
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900">
      <CourseTitle title={currentCourse.rephrased_title} />
      <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
        {/* Hamburger Menu Button */}
        <div className="lg:hidden px-4 py-3 bg-white dark:bg-gray-800 border-b dark:border-gray-700 sticky top-0 z-20">
          <button 
            onClick={() => setIsSidebarOpen(!isSidebarOpen)} 
            className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center space-x-2"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <span className="text-sm font-medium">Course Content</span>
          </button>
        </div>
        
        {/* Sidebar with Overlay */}
        <div className={`
          fixed inset-0 z-30 lg:relative lg:z-0
          transition-transform duration-300 ease-in-out
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        `}>
          {/* Dark Overlay - Mobile Only */}
          {isSidebarOpen && (
            <div 
              className="fixed inset-0 bg-black bg-opacity-50 lg:hidden"
              onClick={() => setIsSidebarOpen(false)}
            />
          )}
          
          <Sidebar {...sidebarProps} onCloseMobile={() => setIsSidebarOpen(false)} />
        </div>

        {/* Main Content */}
        <main className="flex-1 overflow-auto relative">
          <MainContent {...mainContentProps} />
        </main>
      </div>
    </div>
  );
};

export default React.memo(LearnerPage);
