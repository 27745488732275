import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography> 
      <Typography variant="body2" color="textSecondary">
        Effective Date: 15th February 2025
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1" paragraph>
        Open Course ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform, including our website and services.
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect the following types of information:
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>a. Personal Information</strong><br />
          - Name<br />
          - Email address<br />
          - Contact details<br />
          - Educational background (if applicable)<br />
          - Account information (such as username and password)
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>b. Non-Personal Information</strong><br />
          - Browser type and version<br />
          - Device information<br />
          - IP address<br />
          - Location data (general)<br />
          - Usage data and analytics
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>c. Cookies and Tracking Technologies</strong><br />
          We use cookies and similar technologies to enhance your experience on our platform. This includes session cookies, persistent cookies, and tracking pixels.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to:
        </Typography>
        <Typography variant="body2" component="ul" sx={{ pl: 2 }}>
          <li>Provide and improve our services</li>
          <li>Personalize your learning experience</li>
          <li>Communicate with you, including sending updates and promotional materials</li>
          <li>Respond to customer service requests</li>
          <li>Analyze usage trends and improve platform performance</li>
          <li>Ensure platform security and prevent fraud</li>
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell, trade, or rent your personal information to third parties. However, we may share information with third parties in the following cases:
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>- Service Providers:</strong> For hosting, analytics, payment processing, and email delivery services<br />
          <strong>- Legal Requirements:</strong> When required by law or in response to legal processes<br />
          <strong>- Business Transfers:</strong> In case of a merger, sale, or transfer of assets
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          Depending on your location, you may have the following rights regarding your personal data:
        </Typography>
        <Typography variant="body2" component="ul" sx={{ pl: 2 }}>
          <li>Access and Correction: Request access to and correction of your data</li>
          <li>Deletion: Request deletion of your data</li>
          <li>Data Portability: Request to receive your data in a structured format</li>
          <li>Withdraw Consent: Withdraw consent where processing is based on your consent</li>
        </Typography>
        <Typography variant="body1" paragraph>
          To exercise any of these rights, please contact us at <strong>team@opencourse.live</strong>.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          6. Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices of those third-party services.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          7. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our services are not intended for children under 13. We do not knowingly collect personal information from children. If we discover that we have collected information from a child without parental consent, we will take steps to delete it.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Effective Date." We encourage you to review this policy periodically.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Open Course</strong><br />
          Email: team@opencourse.live<br />
          Website: https://opencoursehub.online/
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
