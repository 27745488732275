import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { registerAPI, loginAPI, updateToken } from '../../utils/api';

// Create context with a default value
export const AuthContext = createContext({
  token: '',
  isAuthenticated: false,
  isVerified: false,
  register: async () => {},
  login: async () => {},
  logout: () => {},
  refreshAccessToken: async () => {},
  loginWithLinkedIn: () => {},
  loginWithGoogle: () => {},
  set_login: () => {},
});

// Custom hook for using auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token') || '');
  const [isVerified, setIsVerified] = useState(false);

  // Function to register a new user
  const register = async ({ email, password, name }) => {
    try {
      const response = await registerAPI({ email, password, name });
      if (!response.ok) {
        throw new Error('Registration failed');
      }
      return await response.json();
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const set_login = (data) => {
    setToken(data.access_token);
    setRefreshToken(data.refresh_token);
    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    setIsVerified(data.is_verified);
  }

  // Function to log in a user
  const login = async ({ email, password }) => {
    try {
      const response = await loginAPI({ email, password });
      if (!response.ok) {
        throw new Error('Login failed');
      }
      const data = await response.json();
      setToken(data.access_token);
      setRefreshToken(data.refresh_token);
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      setIsVerified(data.is_verified);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Function to handle LinkedIn OAuth
  const loginWithLinkedIn = () => {
    const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const redirectUri = window.location.origin + process.env.REACT_APP_LINKEDIN_REDIRECT_URI;
    const scope = 'openid profile email';
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${generateRandomState()}`;
    window.location.href = linkedInAuthUrl;
  };

  // Function to handle Google OAuth
  const loginWithGoogle = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = window.location.origin + process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    const scope = 'openid profile email';
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${generateRandomState()}`;
    window.location.href = googleAuthUrl;
  };

  // Generate random state for OAuth security
  const generateRandomState = () => {
    const array = new Uint32Array(8);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  };

  // Function to log out a user
  const logout = () => {
    setToken('');
    setRefreshToken('');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  };

  // Function to refresh access token using refresh token
  const refreshAccessToken = useCallback(async () => {
    if (!refreshToken) return;

    try {
      const response = await updateToken(refreshToken);
      if (!response.ok) {
        throw new Error('Failed to refresh access token');
      }
      const data = await response.json();
      setToken(data.access_token);
      localStorage.setItem('token', data.access_token);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      logout(); // Log out if refresh fails
    }
  }, [refreshToken]);

  useEffect(() => {
    console.log('token refersh job every 5 secons');
    if (!token) return;
    const tokenExpiry = JSON.parse(atob(token.split('.')[1])).exp * 1000; 
    const now = Date.now();

    if (tokenExpiry - now < 5 * 60 * 1000) {
      refreshAccessToken();
    }
    const timeout = setTimeout(refreshAccessToken, tokenExpiry - now - 60 * 1000); 
    return () => clearTimeout(timeout);
  }, [token, refreshAccessToken]);

  const value = {
    token,
    isAuthenticated: !!token,
    isVerified,
    register,
    login,
    logout,
    refreshAccessToken,
    loginWithLinkedIn,
    loginWithGoogle,
    set_login,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
