// src/pages/PlanSelection.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { enrollCourse } from '../utils/api';

const PlanSelection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { courseId, CourseTitle } = location.state || {};
    console.log('t1 courseId', courseId)
    const handleEnroll = async () => {
        try {
            await enrollCourse(courseId);            
            navigate("/learner");
        } catch (error) {
            console.error('Error enrolling in course:', error);
        }
    };


    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 p-6">
            {/* Title */}
            <div className="text-center mb-8">
                <h1 className="text-3xl font-extrabold text-gray-800 mb-2">{CourseTitle || ""}</h1>
                <p className="text-lg text-gray-600">How would you like to continue?</p>
            </div>

            {/* Options Container */}
            <div className="flex flex-wrap justify-center gap-8">
                {/* todo Option 1 */}
                {/* <div className="bg-white border-2 border-blue-300 rounded-lg p-6 w-80 shadow-lg transform hover:scale-105 transition duration-300">
                    <h2 className="text-xl font-bold text-blue-700 mb-4">With an AI Teacher</h2>
                    <ul className="text-gray-600 mb-6 space-y-2">
                        <li>✅ Free Video Lectures</li>
                        <li>✅ Free Assessments</li>
                        <li>✅ Free Certificate</li>
                        <li>✅ Lectures Summary and Notes</li>
                        <li>✅ AI Teacher for 24×7</li>
                        <li>✅ A Hands-On Project</li>
                    </ul>
                    <div className="text-center text-lg font-semibold text-gray-900 mb-4">
                        Rs. 199/month
                    </div>
                    <button className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 shadow-md transition duration-300">
                        Proceed
                    </button>
                </div> */}

                <div className="bg-white border-2 border-blue-300 rounded-lg p-6 w-80 shadow-lg transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                    <div>
                        <h2 className="text-xl font-bold text-blue-700 mb-4">By Yourself</h2>
                        <ul className="text-gray-600 mb-6 space-y-2">
                            <li>✅ Free Video Lectures</li>
                            <li>✅ Free Assessments</li>
                            <li>✅ Free Certificate</li>
                        </ul>
                    </div>
                    <button
                        onClick={() => handleEnroll()}
                        className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 shadow-md transition duration-300 mt-auto">
                        Proceed
                    </button>
                </div>
            </div>


            {/* Cancel Button */}
            {/* <div className="mt-8">
                <button className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 shadow-md transition duration-300">
                    Cancel
                </button>
            </div> */}
        </div>
    );
};

export default PlanSelection;
