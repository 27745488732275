// src/components/navigation/AppBar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import AuthModal from '../../pages/auth/AuthModal';
import { useAuth } from '../../pages/auth/AuthContext';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import navigationItems from './NavigationItems';

const AppBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState('signin');
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, logout } = useAuth();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleLogout = () => {
    logout();
    navigate('/');
  };
  const openSignIn = () => {
    setAuthMode('signin');
    setIsAuthModalOpen(true);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-xl font-bold text-blue-600">
              Open Course
            </Link>
          </div>
          <DesktopMenu
            navigationItems={navigationItems}
            isLoggedIn={isAuthenticated}
            handleLogout={handleLogout}
            openSignIn={openSignIn}
            location={location}
          />
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
            >
              {isMenuOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      <MobileMenu
        isMenuOpen={isMenuOpen}
        navigationItems={navigationItems}
        isLoggedIn={isAuthenticated}
        handleLogout={handleLogout}
        openSignIn={openSignIn}
        location={location}
        toggleMenu={toggleMenu}
      />
      
      <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)} defaultMode={authMode} />
    </nav>
  );
};

export default AppBar;
