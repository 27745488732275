import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  BookOpen, 
  Clock, 
  Award, 
  BarChart2, 
  PlayCircle,
  Calendar,
  ChevronRight,
  AlertTriangle
} from 'lucide-react';
import { getUserProgress, updateCurrentCourse } from '../../utils/api';
import './profile.css';
import { useAuth } from '../auth/AuthContext';

const Profile = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const [userProgress, setUserProgress] = useState(null);
  const [learningStreak, setLearningStreak] = useState(0);
  const { isVerified } = useAuth();
  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        const progress = await getUserProgress();
        setUserProgress(progress.data);
        
        // Calculate stats from current courses
        const currentCourses = progress?.user_progress?.course || [];
        const totalProgress = currentCourses.reduce((sum, course) => sum + (course.progress || 0), 0);
        const averageProgress = currentCourses.length > 0 ? 
          Math.round(totalProgress / currentCourses.length) : 0;
        
        setUserStats({
          coursesEnrolled: currentCourses.length,
          hoursWatched: Math.round(totalProgress / 10), // Approximate hours based on progress
          certificatesEarned: currentCourses.filter(c => c.progress === 100).length,
          averageScore: averageProgress
        });
      } catch (error) {
        console.error('Error fetching user progress:', error);
      }
    };

    fetchUserProgress();
  }, []);

  const [userStats, setUserStats] = useState({
    coursesEnrolled: 0,
    hoursWatched: 0,
    certificatesEarned: 0,
    averageScore: 0
  });

  const statsCards = [
    // {
    //   icon: <BookOpen className="w-6 h-6" />,
    //   label: "Courses Enrolled",
    //   value: userStats.coursesEnrolled
    // },
    // {
    //   icon: <Clock className="w-6 h-6" />,
    //   label: "Hours Watched",
    //   value: userStats.hoursWatched
    // },
    // {
    //   icon: <Award className="w-6 h-6" />,
    //   label: "Certificates Earned",
    //   value: userStats.certificatesEarned
    // },
    // {
    //   icon: <BarChart2 className="w-6 h-6" />,
    //   label: "Average Progress",
    //   value: `${userStats.averageScore}%`
    // }
  ];

  const handleCourseClick = async (courseId) => {
    try {
      await updateCurrentCourse(courseId);
      navigate(`/learner`);
    } catch (error) {
      console.error('Error updating current course:', error);
    }
  };

  return (
    <div className="profile-container">
      {/* Verification Warning Banner */}
      {!isVerified && (
        <div className="verification-warning">
          <AlertTriangle className="w-5 h-5" />
          <span>Please verify your email address to unlock all features</span>
        </div>
      )}

      {/* User Header */}
      {/* <div className="user-header">
        <div className="user-info">
          <img 
            src={`https://api.dicebear.com/7.x/avataaars/svg?seed=${userProgress?.user_progress?.user_id || 'default'}`}
            alt="User Avatar" 
            className="user-avatar"
          />
          <div>
            <h1 className="user-name">User {userProgress?.user_progress?.user_id}</h1>
          </div>
        </div>
        <div className="streak-counter">
          <Calendar className="w-5 h-5" />
          <span>{learningStreak} day streak!</span>
        </div>
      </div> */}

      {/* Stats Grid */}
      <div className="stats-grid mt-6">
        {statsCards.map((stat, index) => (
          <div key={index} className="stat-card">
            <div className="stat-icon">{stat.icon}</div>
            <div className="stat-info">
              <h3 className="stat-value">{stat.value}</h3>
              <p className="stat-label">{stat.label}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Current Courses */}
      <div className="recent-courses mt-6">
        <h2 className="section-title">Continue Learning</h2>
        <div className="courses-grid">
          {userProgress?.user_progress?.course?.map((course) => (
            <div 
              key={course.id} 
              className="course-card"
              onClick={() => handleCourseClick(course.id)}
            >
              <div className="course-thumbnail">
                <img src={course.img} alt={course.rephrased_title} />
                <div className="course-progress">
                  <div 
                    className="progress-bar" 
                    style={{ width: `${course.progress}%` }}
                  />
                </div>
              </div>
              <div className="course-info">
                <h3 className="course-title">{course.rephrased_title}</h3>
                <p className="current-module">
                  <PlayCircle className="w-4 h-4" />
                  {course.professor} - {course.university}
                </p>
                <div className="course-meta">
                  <span className="progress-text">{course.progress}% Complete</span>
                  <ChevronRight className="w-4 h-4" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
