import React, { useState, useEffect } from 'react';
import CoursePlayer from './CoursePlayer';
import Quiz from './Quiz';

const MainContent = ({ course, courseId, activeChapter, activeLecture, isQuizVisible, setIsQuizVisible }) => {
  const [showCompletionMessage, setShowCompletionMessage] = useState(false);
  const [activeTab, setActiveTab] = useState('video');

  if (activeChapter.start_time >= 0 && activeChapter.end_time >= 0) {
  }
  else {
    throw new Error('Start and end are required');
  }

  return (
    <main className="flex-1 p-8">
      <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
        <h2 className="text-center text-2xl font-semibold mb-4">Chapter: {activeChapter?.title}</h2>
        {showCompletionMessage && (
          <div className="mb-4 p-4 bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-100 rounded-lg">
            <p className="text-center">
              🎉 Congratulations! You've completed this chapter.
              {activeTab !== 'quiz' && " Don't forget to take the quiz to test your knowledge!"}
            </p>
          </div>
        )}
        
        <div className="mb-4 border-b border-gray-200">
          <div className="flex gap-4">
            <button
              onClick={() => setActiveTab('video')}
              className={`py-2 px-4 font-medium transition-all duration-300 border-b-2 ${
                activeTab === 'video'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Video Content
            </button>
            <button
              onClick={() => setActiveTab('quiz')}
              className={`py-2 px-4 font-medium transition-all duration-300 border-b-2 flex items-center gap-2 ${
                activeTab === 'quiz'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Quiz
              {showCompletionMessage && activeTab !== 'quiz' && (
                <span className="inline-flex h-2 w-2 rounded-full bg-green-400 animate-pulse"></span>
              )}
            </button>
          </div>
        </div>

        {activeTab === 'video' && (
          <CoursePlayer
            courseId={courseId}
            lectureId={activeLecture?.uniq_id}
            chapterId={activeChapter?.uniq_id}
            videoUrl={'https://www.youtube.com/watch?v=' + activeLecture.video + '&start=' + activeChapter.start_time + '&end=' + activeChapter.end_time}
            setShowCompletionMessage={setShowCompletionMessage}
          />
        )}

        {activeTab === 'quiz' && (
          <div className="transform transition-all duration-300 ease-in-out animate-[fadeIn_0.3s_ease-out]">
            <Quiz 
              course={course} 
              courseId={courseId} 
              lecture={activeLecture.title} 
              chapter={activeChapter.title} 
              chapterId={activeChapter?.uniq_id} 
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default MainContent;
