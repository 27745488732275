import React from 'react';

const CourseTitle = ({ title }) => {
  return (
    <h2 className="m-5 bg-white text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200 text-center">
      {title}
    </h2>
  );
};

export default CourseTitle;
