import React from "react";
import {
  Container,
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const popularTopics = [
    {
      title: "Computer Science",
      url: "/courses?categories=Computer+Science",
    },
    {
      title: "Economics",
      url: "/courses?categories=Economics",
    },
    {
      title: "Biology",
      url: "/courses?categories=Biology",
    },
    {
      title: "Mathematics",
      url: "/courses?categories=Mathematics",
    },
    {
      title: "Physics",
      url: "/courses?categories=Physics",
    },
  ];

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #1a237e, #00bcd4)",
        color: "#fff",
        py: 6,
      }}
    >
      {/* Footer */}
      <Box sx={{ color: "#fff", py: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ fontSize: "1.2rem", letterSpacing: "0.5px" }}
              >
                Open Course
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Empowering learners worldwide with accessible, high-quality
                education.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/open-course-hub/",
                      "_blank"
                    )
                  }
                  color="inherit"
                  aria-label="LinkedIn"
                  sx={{ "&:hover": { color: "#000" } }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://github.com/Lucky-Rathore/OpenCourse",
                      "_blank"
                    )
                  }
                  color="inherit"
                  aria-label="Github"
                  sx={{ "&:hover": { color: "#000" } }}
                >
                  <GitHubIcon />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ fontSize: "1.2rem", letterSpacing: "0.5px" }}
              >
                Learn
              </Typography>
              <Box component="ul" sx={{ pl: 0, listStyle: "none" }}>
                {popularTopics.map((link, index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <Button
                      onClick={() => navigate(link.url)}
                      color="inherit"
                      sx={{
                        p: 0,
                        textTransform: "none",
                        justifyContent: "flex-start",
                        "&:hover": { color: "#000" },
                      }}
                    >
                      {link.title}
                    </Button>
                  </li>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ fontSize: "1.2rem", letterSpacing: "0.5px" }}
              >
                Partners
              </Typography>
              <Box component="ul" sx={{ pl: 0, listStyle: "none" }}>
                {[
                  "Email Us",
                  "Become a Partner",
                  "Enterprises",
                  // "For Governments",
                  "For Universities",
                ].map((link, index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <Button
                      color="inherit"
                      sx={{
                        p: 0,
                        textTransform: "none",
                        "&:hover": { color: "#000" },
                      }}
                      onClick={() =>
                        window.open(
                          `mailto:opencourse.team@gmail.com?subject=Business Partnership Inquiry`,
                          "_blank"
                        )
                      }
                    >
                      {link}
                    </Button>
                  </li>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{
                  fontSize: "1.2rem",
                  letterSpacing: "0.5px",
                  // textAlign: "left",
                }}
              >
                Explore More
              </Typography>
              <Box
                component="ul"
                sx={{
                  pl: 0,
                  listStyle: "none",
                }}
              >
                {[
                  { title: "About Us", component: "about-us" },
                  { title: 'Terms and Conditions', component: 'term-and-conditions' },
                  { title: 'Cancellation and Refund Policy', component: 'cancellation-refund-policy' },
                  { title: 'Privacy Policy', component: 'privacy-policy' },
                  { title: 'Terms of Service', component: 'terms-of-service' },
                  { title: 'Contact Us', component: 'contact-us' },

                ].map((link, index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <Button
                      color="inherit"
                      sx={{
                        p: 0,
                        textTransform: "none",
                        justifyContent: "flex-start",
                        "&:hover": { color: "#000" },
                      }}
                      onClick={() => navigate(`/${link.component}`)}
                    >
                      {link.title}
                    </Button>
                  </li>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4, borderColor: "#ffffff", borderWidth: "2px" }} />
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2">
                © 2024 Open Course. All rights reserved.
              </Typography>
            </Grid>
            <Grid item>
              {/* Privacy Policy, Terms of Service, etc., can be added here */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
export default Footer;
