// React Component
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { subscribe } from '../utils/api';

const SubscribeMeModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const isSubscribed = Cookies.get('isSubscribed');
        if (!isSubscribed) {
            setIsOpen(true);
        }
    }, []);

    const handleSubscribe = async () => {
        if (!email) {
            setMessage('Please enter a valid email address.');
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await subscribe(email);
            setMessage('Thank you for subscribing!');
        } catch (error) {
            console.error('Subscription failed:', error);
            setMessage('Thank you for your interest! We\'ll add you to our list.');
        }

        // Set cookie and close modal after a short delay in all cases
        setTimeout(() => {
            Cookies.set('isSubscribed', 'true', { expires: 30 }); // Expires in 30 days
            setIsOpen(false);
        }, 1500);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96 max-w-full">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Welcome to Open Course!</h2>
                <p className="text-gray-600 mb-6">Our work is in progress. Subscribe for updates and be the first to know.</p>
                {message && (
                    <div className={`mb-4 p-3 rounded ${message.includes('Please enter') ? 'bg-yellow-100 text-yellow-700' : 'bg-green-100 text-green-700'}`}>
                        {message}
                    </div>
                )}
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isSubmitting}
                    className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                    onClick={handleSubscribe}
                    disabled={isSubmitting}
                    className={`w-full ${isSubmitting ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white py-2 px-4 rounded-md transition duration-300 ease-in-out`}
                >
                    {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                </button>
                {!isSubmitting && (
                    <button
                        onClick={() => setIsOpen(false)}
                        className="mt-4 text-sm text-gray-500 hover:text-gray-700"
                    >
                        No thanks, maybe later
                    </button>
                )}
            </div>
        </div>
    );
};

export default SubscribeMeModal;
