import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthModal from './auth/AuthModal';
import { useAuth } from './auth/AuthContext';

const LoginPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  // Redirect to home if already authenticated
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleClose = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  return (
    <div className="min-h-screen">
      <AuthModal 
        isOpen={isModalOpen} 
        onClose={handleClose} 
        defaultMode="signin" 
      />
    </div>
  );
};

export default LoginPage; 