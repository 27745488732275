import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { loginWithGoogleAPI } from '../../utils/api';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { set_login } = useContext(AuthContext);

  useEffect(() => {
    const handleGoogleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      
      // TODO: Verify state parameter matches the one we sent
      
      if (code) {
        try {
          loginWithGoogleAPI(code).then(resp => {
            set_login(resp.data);
            navigate('/');
          });
        } catch (error) {
          console.error('Google callback error:', error);
          navigate('/login?error=google_auth_failed');
        }
      } else {
        navigate('/login?error=no_code');
      }
    };

    handleGoogleCallback();
  }, [navigate, location]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-600">Completing sign in with Google...</p>
      </div>
    </div>
  );
};

export default GoogleCallback; 