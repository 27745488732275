import React from 'react';

const CourseDetailSkeleton = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 animate-pulse">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Course Header Skeleton */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8 flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/3 h-64 bg-gray-200 rounded-lg mb-4 lg:mb-0 lg:mr-6" />
          <div className="flex-1">
            <div className="h-8 bg-gray-200 rounded w-3/4 mb-4" />
            <div className="flex items-center mb-4 space-x-2">
              <div className="h-4 bg-gray-200 rounded w-24" />
              <div className="h-4 bg-gray-200 rounded w-24" />
              <div className="h-4 bg-gray-200 rounded w-32" />
            </div>
            <div className="space-y-2 mb-6">
              <div className="h-4 bg-gray-200 rounded w-full" />
              <div className="h-4 bg-gray-200 rounded w-5/6" />
              <div className="h-4 bg-gray-200 rounded w-4/6" />
            </div>
            <div className="h-10 bg-gray-200 rounded w-32" />
          </div>
        </div>

        {/* Tab Skeleton */}
        <div className="flex space-x-4 border-b border-gray-200 mb-8">
          {[1, 2, 3].map((tab) => (
            <div key={tab} className="h-8 bg-gray-200 rounded w-24" />
          ))}
        </div>

        {/* Content Skeleton */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="h-6 bg-gray-200 rounded w-48 mb-6" />
          <div className="space-y-4">
            {[1, 2, 3].map((item) => (
              <div key={item} className="border rounded-lg p-4">
                <div className="flex justify-between items-center">
                  <div className="h-5 bg-gray-200 rounded w-2/3" />
                  <div className="h-5 bg-gray-200 rounded w-24" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailSkeleton;