import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { paymentRateLimiter } from '../utils/rateLimiter';

//https://chatgpt.com/c/6784b508-ca18-800f-be03-b253b01770f2 for backend code 

const PaymentPage = () => {
  const [selectedCourse, setSelectedCourse] = useState({
    id: 1,
    title: 'Introduction to React',
    price: 29.99,
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Load Razorpay script securely
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCourseSelection = (course) => {
    setSelectedCourse(course);
  };

  const handlePayment = async () => {
    try {
      // Check rate limiting
      if (!paymentRateLimiter.checkLimit('payment')) {
        throw new Error('Too many payment attempts. Please try again later.');
      }

      const response = await axiosInstance.post('/api/payment/create-order', {
        amount: selectedCourse.price * 100,
        courseId: selectedCourse.id,
      });

      const order = response.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: 'INR',
        name: 'EdTech Course Payment',
        description: selectedCourse.title,
        order_id: order.id,
        handler: async function (response) {
          try {
            // Verify payment on backend
            const verificationResponse = await axiosInstance.post('/api/payment/verify', {
              orderId: order.id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
            });

            if (verificationResponse.data.success) {
              // Verify enrollment
              await axiosInstance.post('/api/courses/enroll', {
                courseId: selectedCourse.id,
                paymentId: response.razorpay_payment_id,
              });
              
              navigate('/dashboard');
            }
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          }

        },
        prefill: {
          name: 'John Doe',
          email: 'johndoe@example.com',
          contact: '9999999999',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert(error.message || 'Error processing payment');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Payment</h1>

      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">Select Course</h2>
        <div className="space-y-2">
          {/* Replace these options with dynamic course data */}
          <button
            onClick={() => handleCourseSelection({ id: 1, title: 'Introduction to React', price: 29.99 })}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Introduction to React - $29.99
          </button>
          <button
            onClick={() => handleCourseSelection({ id: 2, title: 'Advanced React', price: 49.99 })}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Advanced React - $49.99
          </button>
        </div>
      </div>

      <h2 className="text-xl font-semibold text-gray-800 mb-4">Course: {selectedCourse.title}</h2>
      <p className="text-lg text-gray-600">Price: ${selectedCourse.price}</p>

      <div className="bg-white p-6 rounded-lg shadow-md mt-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">Payment Details</h3>
        <button
          onClick={handlePayment}
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;
