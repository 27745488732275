import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppBar from './components/layout/AppBar';
import HomePage from './pages/home/HomePage';
import CourseListingPage from './pages/courses/CourseList';
import { Footer } from './components/layout';
import AboutUs from './pages/about/AboutUs';
import Career from './pages/career/Career';
import ContactUs from './pages/contact/ContactUs';
import CourseDetail from './pages/CourseDetail';
import Profile from './pages/profile/profile';
import LearnerPage from './pages/LearnerPage';
import PaymentPage from './pages/PaymentPage';
import { AuthProvider } from './pages/auth/AuthContext';
import PrivateRoute from './PrivateRoute';
import CourseDetailDummy from './pages/CourseDetailDummy';
import PlanSelection from './pages/PlanSelection';
import TermCondition from './TermCondition';
import LoginPage from './pages/LoginPage';
import LinkedInCallback from './pages/auth/LinkedInCallback';
import GoogleCallback from './pages/auth/GoogleCallback';
import Error from "./Error";
import ScrollToTop from './pages/ScrollToTop';
import CancellationRefundPolicy from './CancellationRefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <AppBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/courses" element={<CourseListingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/linkedin/callback" element={<LinkedInCallback />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route
            path="/course-detail-dummy/:courseId"
            element={<CourseDetailDummy />}
          />
          {/* certificate page route */}
          {/* <Route path="/certificate-page" component={CertificatePage} /> */}
          <Route path="/course-detail/:courseId" element={<CourseDetail />} />

          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/learner" element={<LearnerPage />} />
            <Route path="/plan-selection" element={<PlanSelection />} />
          </Route>
          <Route path='/payment' element={<PaymentPage />} />
          <Route path="/term-and-conditions" element={<TermCondition />} />
          <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
