// src/components/navigation/MobileMenu.js
import React from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ isMenuOpen, navigationItems, isLoggedIn, handleLogout, openSignIn, location, toggleMenu }) => {
  return (
    <div 
      className={`md:hidden fixed inset-0 z-50 transform ${
        isMenuOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out`}
    >
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 bg-black/30 backdrop-blur-sm ${
          isMenuOpen ? 'opacity-100' : 'opacity-0'
        } transition-opacity duration-300`}
        onClick={toggleMenu}
      />
      
      {/* Menu Content */}
      <div className="absolute right-0 h-full w-64 bg-white shadow-2xl">
        <div className="flex flex-col h-full">
          {/* Menu Items Container */}
          <div className="flex-1 overflow-y-auto px-4 py-6 space-y-2">
            {navigationItems.map((item) => (
              item.isExternal ? (
                <a
                  key={item.path}
                  href={item.path}
                  className="flex items-center px-4 py-3 rounded-lg text-sm font-medium text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-all duration-200"
                  onClick={toggleMenu}
                >
                  <span className="text-gray-500">{item.icon}</span>
                  <span className="ml-3">{item.label}</span>
                </a>
              ) : (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center px-4 py-3 rounded-lg text-sm font-medium transition-all duration-200 ${
                    location.pathname === item.path
                      ? 'bg-blue-50 text-blue-600'
                      : 'text-gray-700 hover:bg-blue-50 hover:text-blue-600'
                  }`}
                  onClick={toggleMenu}
                >
                  <span className={`${location.pathname === item.path ? 'text-blue-500' : 'text-gray-500'}`}>
                    {item.icon}
                  </span>
                  <span className="ml-3">{item.label}</span>
                </Link>
              )
            ))}
          </div>

          {/* Auth Section */}
          <div className="border-t border-gray-200 px-4 py-4">
            {isLoggedIn ? (
              <div className="space-y-2">
                <Link
                  to="/profile"
                  className="flex items-center w-full px-4 py-3 rounded-lg text-sm font-medium text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-all duration-200"
                  onClick={toggleMenu}
                >
                  <span className="ml-3">Profile</span>
                </Link>
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-3 rounded-lg text-sm font-medium text-red-600 hover:bg-red-50 transition-all duration-200"
                >
                  <span className="ml-3">Logout</span>
                </button>
              </div>
            ) : (
              <button
                onClick={openSignIn}
                className="flex items-center w-full px-4 py-3 rounded-lg text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200"
              >
                <span className="mx-auto">Sign In</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
