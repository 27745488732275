import React from "react";

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-2xl p-8 max-w-md text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Contact Us</h1>
        <p className="text-gray-600 text-lg">
          For any inquiries, feel free to reach out to us at:
        </p>
        <a
          href="mailto:team@opencourse.live"
          className="text-blue-600 text-lg font-medium hover:underline mt-4 block"
        >
          team@opencourse.live
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
